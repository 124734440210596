body {
  overflow-x: hidden;
}
footer {
  box-shadow: 0px -4px 5px -2px var(--bs-gray-200);
}
.about-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.about-card {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.about-card div:first-child {
  max-width: 30vw;
}
.about-card div:nth-of-type(2) {
  max-width: 50vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
}
.backgroundBlue {
  background: radial-gradient(rgb(175, 216, 252) 15%, rgb(255, 255, 255));
}
.backgroundGray {
  background: linear-gradient(
      var(--bs-gray-400),
      rgba(255, 255, 255, 0) 2%,
      rgba(255, 255, 255, 0) 98%,
      var(--bs-gray-400)
    ),
    linear-gradient(90deg, var(--bs-gray-500), white 20%, white 80%, var(--bs-gray-500));
}
.card-img {
  max-width: 384px;
}
.compass {
  min-width: 320px;
  max-width: 100vw;
}
.disabled {
  background-color: var(--bs-gray-100);
}
.fit {
  max-width: fit-content;
}
.main.text {
  font-family: 'Gentium Basic';
  font-size: 1.1em;
  color: var(--bs-gray-600);
}
/*main text with col child*/
.main.text .col {
  max-width: 40vw;
}
.main.text .indent {
  text-indent: 40px;
}
.main .heading {
  font-family: 'Questrial';
  font-size: 1.7em;
  color: var(--bs-gray-700);
}
.main .nav-link {
  font-family: 'Questrial';
  font-size: 1.1em;
  color: var(--bs-gray-500);
}
.main .nav-link:hover {
  background-color: var(--bs-gray-100);
}
.main .subheading {
  font-family: 'Raleway';
  font-size: 1.3em;
  color: var(--bs-gray-600);
  text-align: center;
}
.myCarousel {
  border: 1px solid var(--bs-gray-500);
}
/*pic is 400px * 500px original, I had height as 60vh*/
.myCarousel .img {
  max-width: 40vw;
  height: calc(width * 1.25);
  border: 2px solid var(--bs-gray-500);
  border-radius: 20px;
  animation: 4s infinite img-fade-in;
  animation-timing-function: cubic-bezier(0.8, 0, 0.6, 0);
}
.over-text {
  padding: 0px 1em;
  color: var(--bs-gray-700);
  background-color: rgba(255, 255, 255, 0.7);
}
.p-link {
  color: darkred;
}
.p-link:hover {
  filter: brightness(40%);
}
.slider-back {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 20%,
    rgba(0, 0, 0, 0.6) 30%,
    rgba(0, 0, 0, 0.6) 70%,
    rgba(255, 255, 255, 0) 80%
  );
}
.subheading.lg {
  font-family: 'Raleway';
  font-size: 1.8em;
}
.subheading:not(.subnav) {
  text-shadow: 1px 1px 1px var(--bs-gray-500);
}
.subheading-adj {
  min-width: 172px;
}
.subnav .nav-link {
  padding: 0px 10px;
  cursor: pointer;
}
.TooSmall {
  text-align: center;
}
#banner {
  background-image: url('../img/banner.webp');
}
@media (max-width: 320px) {
  .compass {
    display: none;
  }
}
@media (max-width: 500px) {
  .breakable-links {
    margin: 0px 0px 2em 1em;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 576px) {
  .about-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .about-card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about-card div:first-child {
    max-width: 40vw;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--bs-gray-500);
  }
  .about-card div:nth-of-type(2) {
    margin-top: 1em;
    max-width: 60vw;
  }
  .collapsable {
    display: none;
  }
  .main.text .col {
    max-width: 60vw;
  }
  .main.text .indent {
    text-indent: 20px;
  }
  .navbar-nav .nav-link {
    padding: 0px 10px;
  }
  .subnav {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 320px) {
  .TooSmall {
    display: none;
  }
}
@media (min-width: 576px) {
  .disp-sm {
    display: none;
  }
}
@media (min-width: 768px) {
  .myCarousel .img {
    max-width: 23vw;
  }
}
@keyframes img-fade-in {
  0% {
    opacity: 0;
    transform: translateX(400px);
  }
  10% {
    opacity: 1;
    transform: translateX(0px);
  }
  60% {
    opacity: 1;
    transform: translateX(0px);
  }
  80% {
    opacity: 0;
    transform: translateX(-400px);
  }
  100% {
    opacity: 0;
  }
}
